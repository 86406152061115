import request from 'hb-redux-store/util/request';

export const postLogoutApi = () => request.post('/api/userLogout');

export const postPhoneNoForOTPApi = data => request.post('/api/v2/userLogin', data);

export const postVerifyOTPApi = (data, cb) => request.post('/api/v2/verifyOTP', data, cb);

export const postEmailLoginApi = data => request.post('/api/userLogin?ignore', data);

export const postForgotPasswordApi = data => request.post('/api/forgetPasswordLink', data);

export const getUserEmailApi = ({ userUUID }) => request.get(`/api/userEmail/${userUUID}`);

export const postResetPasswordApi = data => request.post('/api/resetPassword?ignore', data);

export const postVerifyCCAForOfcApi = data => request.post('/api/verifyCCAToken', data);
