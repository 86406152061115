import axios from 'axios';
import config from 'appConfig';
import {queryStringToJSON} from './miscellaneousServices';
import {sendAnalytics} from 'hb-redux-store/analytics';

const RESET_APP_ERROR_CODE = 412;
const RESET_APP_IDLE_TIMEOUT = 30; // in minutes

const getHeaders = () => {
  const {codepushAppVersion: appVersion} = config
  if (config.isApp) {
    return {
      'device-id': config.getDeviceId(),
      'device-type': config.getDeviceType(),
      'client-id': 'app/1.0',
      'app-version-code':
        config?.getBuildNumber() || '',
      'app-version-name':
      appVersion || config?.getVersion() || '',
      'api-version': '1',
    };
  }
  const headers = {
    'device-id': config.getDeviceId(),
    'device-type': 'mobile',
    'client-id': 'web-client/1.0',
    'build-version': process.env.REACT_APP_BUILD_VERSION || 1,
  };
  if (!config.isApp && window?.location?.search) {
    const qp = queryStringToJSON(window.location.search);
    const headerKeys = [
      {qpKey: 'utm_source', headerKey: 'utm-source'},
      {qpKey: 'utm_medium', headerKey: 'utm-medium'},
      {qpKey: 'utm_campaign', headerKey: 'utm-campaign'},
    ];
    if (qp) {
      headerKeys.map(key => {
        if (qp[key.qpKey]) headers[key.headerKey] = qp[key.qpKey];
      });
    }
  }
  if (config.getDeviceType() === 'mweb') {
    return headers;
  }
  headers['device-type'] = config.getDeviceType();
  return headers;
};

let baseUrl = '';
if (config.isApp && config.ENV.API_URL) {
  baseUrl = config.ENV.API_URL;
} else {
  baseUrl = process.env.REACT_APP_API_END_POINT || '';
}

const getURL = url => {
  const relativeURL  = url.slice()[0] === '/'; // check if relative URL
  if (relativeURL) {
    return baseUrl + url;
  }
  return url;
};

const createRequestConfig = ({method, url, data, params}) => {
  const finalURL = getURL(url);
  const config = {method, data, params, url: finalURL};
  if (url.indexOf('http') === 0) {
    config.url = url;
  }
  return config;
};

const shouldCallHardReset = ts => {
  const timeSinceLastAPI = Math.abs(new Date() - new Date(ts));
  if (timeSinceLastAPI) {
    const minutesDiff = Math.floor(timeSinceLastAPI / 1000 / 60);
    return minutesDiff >= RESET_APP_IDLE_TIMEOUT;
  }
};

const addRequestInterceptors = () => {
  // Add any interceptor here
  axios.interceptors.request.use(
    requestConfig => {
      if (config.isApp) {
        // App
        config.getData('lastAPITime').then(resp => {
          if (shouldCallHardReset(resp)) {
            config.hardResetEvent();
          }
          config.storeData('lastAPITime', new Date());
        });
      } else {
        const ts = config.getItem('lastAPITime');
        if (ts && shouldCallHardReset(ts)) {
          console.log('Calling hard reset without redirect.');
          window.dispatchEvent(config.hardResetEvent);
        }
        config.setItem('lastAPITime', new Date());
      }
      return requestConfig;
    },
    err => {
      // DO SOMETHING WITH ERROR
      return Promise.reject(err);
    },
  );
};

const addResponseInterceptors = () => {
  axios.interceptors.response.use(
    response => {
      // console.warn('Response', response);
      if (response.data && response.data.action === 'failure') {
        console.log('Explicit Failing api from backend');
        return Promise.reject(response.data);
      }

      return response.data;
    },
    error => {
      // Do something with response error
      return Promise.reject(error.response || error);
    },
  );
};

const adcCodePreconditioFailedInterceptor = () => {
  // If any API fails with unique code (412) status: ADC_CODE_PRECONDITION_FAILED,
  // call launchInfo
  // and redirect to home with snackbar
  axios.interceptors.response.use(
    response => response,
    errorResp => {
      // Getting 421 is string so comparing using ==
      if (errorResp?.error?.errorCode == RESET_APP_ERROR_CODE) {
        if (config.isApp) {
          // App
          //Handle here for app
          console.log('Calling hard reset ==== redirecting. from App');
          config.hardResetRedirectEvent();
        } else {
          console.log('Callign hard reset ==== redirecting.');
          window.dispatchEvent(config.hardResetRedirectEvent);
        }
        return Promise.reject({
          ...errorResp,
          data: {
            description: 'Something went wrong. Redirecting you to homepage.',
          },
        });
      }
      return Promise.reject(errorResp);
    },
  );
};

// const unauthorizedAccess401Interceptors = () => {
//   axios.interceptors.response.use(
//     response => response,
//     error => {
//       debugger
//       if (error.status === 401) {
//         setTimeout(() => {
//           setAppNotification('Unauthorised Access. Please login to access this service');
//         })
//
//       }
//       return Promise.reject(error);
//     }
//   );
// };
addRequestInterceptors();
addResponseInterceptors();
adcCodePreconditioFailedInterceptor();
// unauthorizedAccess401Interceptors();

const createRequest = configuration => {
  // Add any common headers and other stuff here
  // Add some base URL here

  const headers = getHeaders();
  // console.warn('create request', config?.firebaseAnalytics());

  // Analytics
  const key = 'API_Call';
  const clevertapKey = 'API Call';
  const payload = {
    appVersionCode: headers['app-version-code'],
    appVersionName: headers['app-version-name'],
    apiVersion: headers['api-version'],
    clientId: headers['client-id'],
    deviceId: headers['device-id'],
    deviceType: headers['device-type'],
    method: configuration?.method,
    url: configuration?.url,
    params: JSON.stringify(configuration?.params),
  };
  sendAnalytics({
    key,
    payload,
    firebasePriority: 3,
    clevertapPriority: 3,
    clevertapKey,
  });

  //  console.warn('create request', configuration, headers);
  return axios({
    ...configuration,
    headers: headers,
  });
};

const get = (url, params = {}) => {
  const reqConfig = createRequestConfig({
    method: 'GET',
    url: url,
    params,
  });
  return createRequest(reqConfig);
};

const post = (url, data = {}, params = {}) => {
  const reqConfig = createRequestConfig({
    method: 'POST',
    url: url,
    data,
    params,
  });
  return createRequest(reqConfig);
};

const put = (url, data = {}, params = {}) => {
  const reqConfig = createRequestConfig({
    method: 'PUT',
    url: url,
    data,
    params,
  });
  return createRequest(reqConfig);
};

const del = (url, data = {}, params = {}) => {
  const reqConfig = createRequestConfig({
    method: 'DELETE',
    url: url,
    data,
    params,
  });
  return createRequest(reqConfig);
};

export default {get, post, put, del};
